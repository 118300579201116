<script setup>
import Item from '@/Components/Menu/Item.vue';
import TeamSelector from '@/Components/Menu/TeamSelector.vue';
import menuCloseIcon from '@i/menu-close.svg';
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

defineProps({ open: Boolean, locale: String, otherLocale: String, user: Object });
const emits = defineEmits(['close']);
const route = inject('route');
</script>

<template>
    <div
        :class="{ '-translate-y-full': !open }"
        class="fixed top-0 left-0 right-0 z-20 transition-transform duration-300 bg-blue text-white text-4"
    >
        <div class="h-8"></div>

        <div class="flex justify-between mx-10">
            <Link :href="route('location.planning.index')">
                <img alt="Flexable logo" src="@/../img/flexable-logo-sunset.svg" />
            </Link>
            <button class="relative flex items-center justify-center w-5 h-5 m-3 mr-0">
                <img
                    :src="menuCloseIcon"
                    alt="close menu"
                    class="absolute transition-opacity duration-300"
                    @click="$emit('close')"
                />
            </button>
        </div>
        <div class="h-8"></div>
        <ul class="flex flex-col mx-10 mb-2">
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <TeamSelector :user="user" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Planning')" routeName="location.planning.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t(`Fl@xr's`)" routeName="location.flexers.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Invoices')" routeName="location.invoices.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Approve Hours')" routeName="location.approve-hours.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Messages')" routeName="location.chat.index" />
            </li>

            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Management')" routeName="location.management.index" />
            </li>
        </ul>
    </div>
</template>
