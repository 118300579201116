<script setup>
import Item from '@/Components/Menu/Item.vue';
import { useNotification } from '@/composables/notification';
import { firebaseEnabled } from '@/firebaseConfig';
import { useChatStore } from '@/stores/chat';
import { useNavigationStore } from '@/stores/navigation';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { Head, Link, router, usePage } from '@inertiajs/vue3';
import { onClickOutside, tryOnBeforeUnmount } from '@vueuse/core';
import { computed, inject, onMounted, onUpdated, ref } from 'vue';

import MobileMenu from '@/Layouts/Partials/MobileMenuEmployer.vue';

import Dropdown from '@/Components/Menu/Dropdown.vue';
import TeamSelector from '@/Components/Menu/TeamSelector.vue';
import menuIcon from '@i/menu.svg';

const route = inject('route');
const store = useNavigationStore();
const props = computed(() => usePage().props);
const chat = useChatStore();
const notification = useNotification();

const hasLocationTeams = computed(() => usePage().props.location_teams.length !== 0);

if (firebaseEnabled) {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
        if (!firebaseEnabled) {
            return;
        }
        if (user && typeof chat?.selectGroupUnsubscribe !== 'function') {
            chat.getGroups();
            return;
        }

        await chat.authenticateWithFirebase();
        await chat.getGroups();
    });
}

defineProps({
    title: String,
});

onMounted(async () => {
    store.setRoute(usePage().props.route.previous_name, usePage().props.route.previous);
    store.setRoute(usePage().props.route.current_name, usePage().props.route.current);

    handleNotification();
});

onUpdated(() => {
    handleNotification();
});

tryOnBeforeUnmount(() => {
    if (typeof chat.getGroupsUnsubscribe === 'function') {
        chat.getGroupsUnsubscribe();
    }
    if (typeof chat.selectGroupUnsubscribe === 'function') {
        chat.selectGroupUnsubscribe();
    }
});

const handleNotification = () => {
    if (usePage().props.flash?.notification !== null) {
        notification.show(
            usePage().props.flash.notification.message,
            usePage().props.flash.notification.sent_at,
            usePage().props.flash.notification.type,
        );
    }
};

const logout = () => {
    router.post(route('logout'));
};

const showingMobileMenu = ref(false);
const mobileMenu = ref(null);

onClickOutside(mobileMenu, () => (showingMobileMenu.value = false));
</script>

<template>
    <div>
        <Head :title="title" />

        <div class="lex force-scroll flex-col bg-gray-medium">
            <!-- Page Heading -->
            <header class="min-h-[320px] w-full bg-blue text-4 text-white xl:min-h-[360px] 2xl:min-h-[320px]">
                <div class="container flex min-h-20 items-stretch justify-between bg-blue">
                    <div class="text base flex hidden flex-wrap items-center gap-x-8 font-bold md:text-lg lg:flex">
                        <Link :href="route('location.planning.index')">
                            <img
                                alt="Flexable logo"
                                class="my-6"
                                src="@/../img/flexable-logo-sunset.svg"
                            >
                        </Link>
                        <template v-if="hasLocationTeams">
                            <Dropdown
                                :items="[
                                    { label: $t('Calender'), routeName: 'location.planning.index' },
                                    { label: $t('Sign-ups'), routeName: 'location.planning.todo.index' },
                                ]"
                                :label="$t('Planning')"
                            />
                            <Item
                                :label="$t(`Fl@xr's`)"
                                route-name="location.flexers.index"
                            />
                            <Item
                                :label="$t('Invoices')"
                                route-name="location.invoices.index"
                            />
                            <Item
                                :label="$t('Approve Hours')"
                                route-name="location.approve-hours.index"
                            />
                            <Item
                                :label="$t('Messages')"
                                route-name="location.chat.index"
                            />
                            <Item
                                :label="$t('Management')"
                                route-name="location.management.index"
                            />
                        </template>
                    </div>

                    <div class="z-10 flex hidden items-center gap-4 lg:flex">
                        <TeamSelector :user="props.user" />
                    </div>

                    <div class="text base flex flex-wrap items-center gap-x-8 font-bold md:text-lg lg:hidden">
                        <Link :href="route('location.planning.index')">
                            <img
                                alt="Flexable logo"
                                class="my-6"
                                src="@/../img/flexable-logo-sunset.svg"
                            >
                        </Link>
                    </div>
                    <div class="flex items-center lg:hidden">
                        <button class="relative m-3 mr-0 flex h-5 w-5 items-center justify-center">
                            <img
                                :class="{ 'pointer-events-none opacity-0': showingMobileMenu }"
                                :src="menuIcon"
                                alt="open menu"
                                class="absolute transition-opacity duration-300"
                                @click="showingMobileMenu = true"
                            >
                        </button>
                    </div>
                </div>
            </header>

            <MobileMenu
                ref="mobileMenu"
                v-model:open="showingMobileMenu"
                :user="props.user"
                @close="showingMobileMenu = false"
            />
            <!-- Page Content -->
            <main class="container my-15">
                <div class="relative -top-[200px] rounded-2xl bg-white p-10 shadow xl:-top-[250px]">
                    <div class="flex flex-col gap-8 xl:flex-row">
                        <div
                            v-if="$slots.sidebar"
                            class="flex flex-col gap-2 text-blue xl:w-2/6"
                        >
                            <slot name="sidebar" />
                        </div>
                        <div class="flex w-full flex-col justify-between">
                            <slot />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
